import React from "react";
// , { Fragment, useRef, useState, useEffect }
// import { createConnector } from 'react-instantsearch';
// import { connectHits } from 'react-instantsearch-dom';
import {
    InstantSearch,
    SearchBox,
    RefinementList,
    // SortBy,
    Stats,
    Pagination,
    Highlight,
    // Menu,
    // ClearRefinements,
    Hits,
    HitsPerPage,
    HierarchicalMenu,
    CurrentRefinements
} from 'react-instantsearch-hooks-web';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch('DENBKL9FSR', '4b15e2f67a7b0267e8f45c25edc32a92');


function Searchbox2() {

    return (
        <div >
            <InstantSearch
                searchClient={searchClient}
                className="container-fluid flex justify-center"
                indexName="gamelyric"
            >
                <header className="header  flex justify-center px-8  pt-8">
                    <SearchBox
                        classNames={{
                            root: 'p-3 shadow-sm',
                            form: 'relative',
                            input: 'flex justify-between w-[600px] pl-9 pr-3 py-2 bg-white border border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 hover-focus:ring-sky-900 rounded-md focus:ring-1 ',
                            submitIcon: 'w-6',
                        }}
                        placeholder='Search for business' />

                </header>

                <div className="flex justify-center pb-8">
                    <Stats />
                </div>

                <div className="flex justify-center">
                    <div className="flex justify-center ">
                        <RightColumn></RightColumn>
                        <LeftColumn></LeftColumn>
                    </div>

                </div>
                <main>

                </main>
            </InstantSearch>;

        </div>

    );
}

const RightColumn = () =>
    <div className="w-1/4 ml-4 ">
        <div className=" ml-10 py-4">
            <RefinementList searchable={true} searchablePlaceholder={'Investor'} attribute="Investor 1" />
            <RefinementList attribute="Investor 2" />
            <RefinementList attribute="Investor 3" />

        </div>

        <span className="flex justify-center text-3xl pt-5">Segment</span>
        <HierarchicalMenu className="ml-10 mb-10"
            attributes={[
                'Business'
            ]}
        />

    </div>;

const LeftColumn = () =>
    <div className="mb-[24px]  w-3/4">
        <CurrentRefinements className="p-2 px-14 " />
        <div className="flex justify-end">
            <Hits className="flex flex-wrap" hitComponent={Hit} />
        </div>

        <div className="w-70 flex justify-center p-4 gap-7 relative right-20 pr-20">
            <Pagination />
            <HitsPerPage
                items={[
                    { label: '8 hits per page', value: 8, default: true },
                    { label: '16 hits per page', value: 16 },
                ]}
            />

        </div>

    </div>


// const Content = createConnector({
//     displayName: 'ConditionalResults',
//     getProvidedProps(props, searchState, searchResults) {
//         const noResults = searchResults.results
//             ? searchResults.results.nbHits === 0
//             : false;
//         return { query: searchState.query, noResults };
//     },
// })(({ noResults, query }) => {
//     const rightColumn = noResults
//         ? <div id="no-results-message">
//             <p>
//                 We didn't find any results for the search <em>{query}</em>.
//             </p>
//             <ClearRefinements />
//         </div>
//         : <RightColumn />;
//     return (
//         <div>
//             <div className={noResults ? 'no-results' : ''} id="left-column">
//                 <h5>Category</h5>
//                 <RefinementList attribute="categories" />
//                 <h5>Brand</h5>
//                 <RefinementList
//                     attribute="brand"
//                     withSearchBox
//                     translations={{ noResults: 'No matching brand' }}
//                 />
//                 <h5>Price</h5>
//                 {/* <ConnectedRange attribute="price" /> */}
//                 <h5>Type</h5>
//                 <Menu attribute="type" />
//             </div>
//             {rightColumn}
//         </div>
//     );
// });



function Hit({ hit }) {
    return (
        <div className="">
            <div className="text-bold text-xl">
                {hit.Company}
            </div>

            <div className="text-red-500">
                <Highlight attribute="Location" hit={hit} />
            </div>
            <div className="text-green-800">
                <Highlight attribute="Amount" hit={hit} />
            </div>

            <span >Investors - </span>

            <div className="flex flex-wrap">
                <div className="block">
                    <Highlight attribute="Investor 1" hit={hit} />
                    <span>,  </span>
                </div>

                <div>
                    <Highlight attribute="Investor 2" hit={hit} />
                    <span>,  </span>
                </div>
                <Highlight attribute="Investor 3" hit={hit} />
            </div>

        </div>
    );
}

export default Searchbox2;
