import React from "react";
import './index.css'
import Navbar from "./components/Navbar";
import HomePage from "./components/HomePage"
import Upload from "./components/Upload"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { CompanyContextProvider } from "./context/CompanyContext";
import { StyledEngineProvider } from '@mui/material/styles';

function App() {
  return (
    <StyledEngineProvider injectFirst>
    <CompanyContextProvider >
      <Router>
        <main className='App'>
          <Navbar />
          <div >
            <Routes>
              <Route path='/' element={<HomePage />} />
              <Route path='/upload' element={<Upload />}/>

            </Routes>
          </div>
        </main>

      </Router>
    </CompanyContextProvider>
    </StyledEngineProvider>

  );
}

export default App;