import React,
 {
    useContext, 
    useEffect,
    useState   
} from "react";
import CompanyFinder from "../apis/CompanyFinder";
import {
    CompanyContext
    ,CompanyContextProvider
 } from "../context/CompanyContext"

const CompanyList = (props) => {
    const {company,setCompany}= useContext(CompanyContext)
    const [loading, setLoading] = useState(true);
    
    useEffect(()=> {
        async function fetchd(){
            try {
                const response = await CompanyFinder.get("/companydata")
                console.log(response)
                setCompany(response.data.data.company)
                setLoading(false)
            } catch (error) {
                
            }
        }
        fetchd()
            
    },[])

    return (
        <>
        </>
    )
}

export default CompanyList