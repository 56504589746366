import React from 'react';
import { useState, createContext } from 'react';

export const CompanyContext = createContext();

export const CompanyContextProvider = props => {
    const [company, setCompany] = useState([]);

    return (
        <CompanyContext.Provider value={{
            company,
            setCompany
        }}>
            {props.children}
        </CompanyContext.Provider>
    )
}