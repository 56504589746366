import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import CompanyFinder from "../apis/CompanyFinder";


// const searchClient = algoliasearch('DENBKL9FSR', '4b15e2f67a7b0267e8f45c25edc32a92');


function Summary() {

    const [companyAmount, setCompanyAmount] = useState("")
    const [companyCount, setCompanyCount] = useState(0)
    const [companyInvestor, setCompanyInvestor] = useState(0)

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchd() {
            try {
                const response = await CompanyFinder.get("/getsummary1")
                console.log(response + "is the total amount invested")
                setCompanyAmount(response.data.data.company.sum)
                setCompanyCount(response.data.data.company.count)
                setCompanyInvestor(response.data.data.company.total_investors)

                console.log(JSON.stringify(response, null, 2))
                setLoading(false)
            } catch (error) {

            }
        }
        fetchd()

    }, [])


    return (
        <div class="flex align-middle justify-center rounded-lg  align-center  ml-[4%] mr-[4%]">
            {loading ? (
                <>
                </>
            ) : (
                <>
                    <div class="max-w-sm p-8 pt-6 pl-6 m-4 bg-white border border-gray-200 rounded-lg shadow ">

                        <div className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="56" viewBox="0 0 67 56" fill="none">
                                <g opacity="0.3">
                                    <ellipse cx="31.5837" cy="24.7058" rx="23.861" ry="23.8844" fill="#34CAA5" />
                                    <ellipse cx="4.14385" cy="4.40407" rx="3.57916" ry="3.58265" fill="#34CAA5" />
                                </g>
                                <path d="M35.7036 36.5083C35.7036 39.0759 37.6721 41.1459 40.1179 41.1459H45.1088C47.2364 41.1459 48.9664 39.3347 48.9664 37.1055C48.9664 34.6772 47.9125 33.8214 46.3417 33.2641L38.3283 30.4775C36.7575 29.9202 35.7036 29.0644 35.7036 26.6361C35.7036 24.4069 37.4335 22.5957 39.5611 22.5957H44.5521C46.9978 22.5957 48.9664 24.6657 48.9664 27.2332" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M42.3213 19.929V43.8133" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M42.3212 51.7747C53.3029 51.7747 62.2054 42.8636 62.2054 31.8711C62.2054 20.8786 53.3029 11.9675 42.3212 11.9675C31.3395 11.9675 22.437 20.8786 22.437 31.8711C22.437 42.8636 31.3395 51.7747 42.3212 51.7747Z" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                            <a href="#">
                                <h5 class="mb-2 mt-3 ml-2 pr-3 text-2xl font-semibold tracking-tight text-gray-900 dark:text-black">Investors Participated</h5>
                            </a>
                        </div>

                        <p class="flex align-center justify-center text-[44px]  font-extrabold  mb-3 text-gray-500 dark:text-gray-600"> {companyInvestor}</p>
                        
                    </div>


                    <div class="max-w-sm p-8 pt-6 pl-6 m-4 bg-white border border-gray-200 rounded-lg shadow ">

                        <div className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="56" viewBox="0 0 67 56" fill="none">
                                <g opacity="0.3">
                                    <ellipse cx="31.5837" cy="24.7058" rx="23.861" ry="23.8844" fill="#34CAA5" />
                                    <ellipse cx="4.14385" cy="4.40407" rx="3.57916" ry="3.58265" fill="#34CAA5" />
                                </g>
                                <path d="M35.7036 36.5083C35.7036 39.0759 37.6721 41.1459 40.1179 41.1459H45.1088C47.2364 41.1459 48.9664 39.3347 48.9664 37.1055C48.9664 34.6772 47.9125 33.8214 46.3417 33.2641L38.3283 30.4775C36.7575 29.9202 35.7036 29.0644 35.7036 26.6361C35.7036 24.4069 37.4335 22.5957 39.5611 22.5957H44.5521C46.9978 22.5957 48.9664 24.6657 48.9664 27.2332" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M42.3213 19.929V43.8133" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M42.3212 51.7747C53.3029 51.7747 62.2054 42.8636 62.2054 31.8711C62.2054 20.8786 53.3029 11.9675 42.3212 11.9675C31.3395 11.9675 22.437 20.8786 22.437 31.8711C22.437 42.8636 31.3395 51.7747 42.3212 51.7747Z" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                            <a href="#">
                                <h5 class=" mb-2 mt-3 ml-2 pr-3 text-2xl font-semibold tracking-tight text-gray-900 dark:text-black">Total Funding Raised</h5>
                            </a>
                        </div>

                        <p class="flex align-center justify-center  text-[44px]  font-extrabold  mb-3 text-gray-500 dark:text-gray-600 "> ${new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(companyAmount)}</p>
                        
                    </div>


                    <div class="max-w-sm p-8 pt-6 pl-6 m-4  bg-white border border-gray-200 rounded-lg shadow ">
                        <div className="flex">
                        <svg xmlns="http://www.w3.org/2000/svg" width="67" height="56" viewBox="0 0 67 56" fill="none">
                            <g opacity="0.3">
                                <ellipse cx="31.5837" cy="24.7058" rx="23.861" ry="23.8844" fill="#34CAA5" />
                                <ellipse cx="4.14385" cy="4.40407" rx="3.57916" ry="3.58265" fill="#34CAA5" />
                            </g>
                            <path d="M35.7036 36.5083C35.7036 39.0759 37.6721 41.1459 40.1179 41.1459H45.1088C47.2364 41.1459 48.9664 39.3347 48.9664 37.1055C48.9664 34.6772 47.9125 33.8214 46.3417 33.2641L38.3283 30.4775C36.7575 29.9202 35.7036 29.0644 35.7036 26.6361C35.7036 24.4069 37.4335 22.5957 39.5611 22.5957H44.5521C46.9978 22.5957 48.9664 24.6657 48.9664 27.2332" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M42.3213 19.929V43.8133" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M42.3212 51.7747C53.3029 51.7747 62.2054 42.8636 62.2054 31.8711C62.2054 20.8786 53.3029 11.9675 42.3212 11.9675C31.3395 11.9675 22.437 20.8786 22.437 31.8711C22.437 42.8636 31.3395 51.7747 42.3212 51.7747Z" stroke="#34CAA5" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        <a href="#">
                            <h5 class="mb-2 mt-3 ml-2 pr-3 text-2xl font-semibold tracking-tight text-gray-900 dark:text-black">Companies Invested</h5>
                        </a>

                        </div>
                        <p class="flex align-center justify-center text-[44px]  font-extrabold  mb-3 text-gray-500 dark:text-gray-600">{companyCount}</p>
                        
                    </div>

                </>
            )}

        </div>

    )



}




export default Summary;
