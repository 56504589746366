import React, { useState } from 'react';
import Papa from 'papaparse';
// import axios from 'axios';
import CompanyFinder from '../apis/CompanyFinder';


function Upload() {

    const [selectedFile, setSelectedFile] = useState(null);
    const [parsedData, setParsedData] = useState(null);
    const [status, setStatus] = useState(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
        parseFile(event.target.files[0]);
    };

    const parseFile = (file) => {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            complete: function (results) {
                setParsedData(results.data);
            },
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Parse CSV file
        const file = selectedFile;
        const results = await new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: resolve,
                error: reject,
            });
        });

        // Set parsed data
        setParsedData(results.data);
        try {
            // login first 
            // "name" : "gaurav",
            // "email" :"gg@gmail.com",
            // "password" : "1234567"
            const login = await CompanyFinder.post('/user/login', { username, email, password })
            if (login) {
                const response = await CompanyFinder.post('/companyDataUpload', results.data);
                setStatus(response.data.message); 
            } else {
                setStatus('Error logging user')
                return login.error
            }
            // Send parsed data to backend
        } catch (error) {
            console.error(error);
            setStatus('Error uploading file');
        }
    }

    return (
        <div className="pt-[80px] block">
            <div>
                <h1>
                    UPLOAD DATA TO companyData TABLE 
                </h1>
            </div>
            {status && (
                <div>
                    <h2>API Status</h2>
                    <p>{status}</p>
                </div>
            )}


            <form onSubmit={handleSubmit}>
                <div >
                    <label>
                        Email:
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </label>
                    <label>
                        Password:
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    <label>
                        Username:
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </label>

                </div>
                <label htmlFor="csvFileInput">Upload CSV File</label>
                <input id="csvFileInput" type="file" accept=".csv" onChange={handleFileChange} />
                <button type="submit">Submit</button>
            </form>
            {parsedData && (
                <div>
                    <h2>Parsed Data:</h2>
                    <pre>{JSON.stringify(parsedData, null, 2)}</pre>
                </div>
            )}


        </div>
    )
}
export default Upload;
