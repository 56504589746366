import React, { useContext} from "react";
// import { Routes, Route } from "react-router-dom";
import Graph from "./Graph";
import Summary from "./Summary";
import CompanyList from "./CompanyList"
import Searchbox2 from "./Searchbox2.js"
// import CompanyFinder from "../apis/CompanyFinder";
import {CompanyContext} from "../context/CompanyContext"

// firebase old 
// import { db, database, auth, firestore } from "../firebase/Firebaseconfig"
// import firebase from "../firebase/Firebaseconfig";
// import FirebaseAuth from "../firebase/FirebaseAuth";
// import { signInAnonymously } from "firebase/auth"
// import { collection, getDocs } from "firebase/firestore"



function HomePage() {
  const {company}= useContext(CompanyContext)

  return (
    <div className="pt-[80px] block bg-[#F5F5F5]">
      <div className="">
        <CompanyList />
      </div>

      {/* graph and summary  */}
      <div className="block ">

        <div>
          <Graph data={company} />
        </div>

        <div className="">
          <Summary />
        </div>

      </div>


      {/* search part  */}
      <div className="text-center p-5 font-extrabold  hover:text-[#360f36] text-lg">
        <h1>Advanced Search </h1>
      </div>
      <div>
        {/* <SearchBox/> */}
        <Searchbox2 />
      </div>

      {/* result of search and placeholder */}
    </div>
  );

}

export default HomePage;



