import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import { useCSVReader as CSVReader } from "react-papaparse";
// import Data from "../assets/Data.csv"
// import Papa from 'papaparse';
// import ApexCharts from 'apexcharts'
import { Bar as Bar2 } from "react-chartjs-2"
import { Chart as Chartjs, BarElement } from "chart.js/auto"

import Box from '@mui/material/Box';

Chartjs.register(
    BarElement
)

const colors = [ '#9AD9DB', '#E5DBD9', '#98D4BB', '#EB96AA', '#E9BBB5', '#E7CBA9', '#AAD9CD', '#E8D595', '#8DA47E', '#008080', '#00FFFF', '#808000', '#800000', '#FF0000', '#00FF00', '#FF69B4', '#FF6347', '#FFD700', '#E9BBB5'];

function Graph(data_orig) {

    const [isLoading, setisLoading] = useState(true)
    const business_data = new Map();

    // data cleanup for graph 
    // business and amount data 


    //  way to map data for chartjs 
    const [business_data2, setbusiness_data2] = useState({
        labels: [],
        datasets: [{
            label: "Amount raised", // what this represents
            data: []
            // backgroundColor: colors
        }]
    })

    useEffect(() => {
        (async () => {
            await data_orig.data.forEach(company => {
                if (business_data.has(company.business)) {
                    const rev = business_data.get(company.business)
                    business_data.set(company.business, rev + company.amount)
                } else {
                    business_data.set(company.business, company.amount)
                }
            });
            const data = await Array.from(business_data, ([business, amount]) => ({ business, amount }))

            await setbusiness_data2({
                labels: data.map((entry) => entry.business),
                datasets: [{
                    label: "Amount raised", // what this represents
                    data: data.map((entry) => entry.amount),
                    backgroundColor: colors
                }]
            })

            setisLoading(false)
        })();
    }, [data_orig]);

    return (
        <div>

            {
                isLoading ? (
                    <div className=" sm:p-4 block overflow-auto font-medium ">
                        Loading ........
                    </div>
                ) : (
                    <div className=" sm:p-4 block overflow-auto font-medium ">
                        {/* <pre>{JSON.stringify(data_orig, null, 2)}</pre> */}

                        <h1 className="text-center p-4 text-bold hover:text-[#360f36] text-lg">Funding Raised in Week 31 Jan to 7 Feb of 2023</h1>

                        <div className="flex align-center justify-center ml-[4%] mr-[4%]">
                            <Box
                                sx={{
                                    width: 900,
                                    height: 500,
                                    backgroundColor: 'white',
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        opacity: [1.9, 1.8, 1.7],
                                        border: 1.8,
                                        borderBlockColor: 'green',
                                    },
                                    borderRadius: 5,
                                    padding: 4,

                                }}
                            >

                                <div className="flex justify-center align-centre">
                                    <Bar2 className={" items-center justify-center h-[400px] flex"} data={business_data2} options={{ maintainAspectRatio: false}}>
                                    </Bar2>
                                </div>
                            </Box>



                        </div>


                        {/* <BarChart
            width={1000}
            height={500}
            data={data_orig}
            margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="business" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="amount" 
            radius={10}
            barSize={10} 
            fill="#2F4F4F" 
            label={{ position: 'top' }}>
            
            </Bar>
                            </BarChart>
                            */}

                    </div>

                )}

        </div>
    );
}

export default Graph;

